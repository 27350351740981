/** @format */

import React, { useState } from 'react';
import ProjectBar from '../components/ProjectBar';

import Layout from '../components/layout';
import SmallPhoto from '../components/SmallPhoto';
import HalfPhoto from '../components/HalfPhoto';

import Photo from '../components/Photo';
// import Text from "../components/Text"
import Title from '../components/Title';
// import Test from '../components/test.md';

import penskecover from '../images/penskecoverlarge.jpg';
import yardcover from '../images/ymcover.png';

import infoarch from '../images/infoarch.jpg';
import ym1 from '../images/myyard.png';
import ym2 from '../images/unitinfo.png';
import ym3 from '../images/yardcheck.png';
import ym4 from '../images/mapview.png';
import ym5 from '../images/createtask.png';
import ym6 from '../images/ycblurry.png';
import ym7 from '../images/ycps.png';
import yc8 from '../images/ycearly.png';
import yc9 from '../images/tasks.png';

import { navigate } from 'gatsby-link';

import lu from '../images/lucover2.png';
import mb from '../images/mbcoverlarge.png';
import otherwork from '../images/otherwork.jpg';
import ym from '../images/ymcoverlarge.png';

const CyberCMS = () => {
    return (
        <Layout>
            <Title
                title="Penske Truck Rental"
                subtitle="Digitizing Yard Management to Streamline Daily Operations"
                categories="UX/UI Design, Front-End Development, User Research"
            />
            <Photo
                link={yardcover}
                subtitle="My Yard: Yard Manager Home Page"
            />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h2>Overview</h2>
                        <h3 className="first">Roles</h3>
                        <p className="firstp">Product Design, User Research</p>
                        <h3>Client</h3>
                        <p className="firstp">Penske Truck Rentals</p>
                        <h3>Problem</h3>
                        <p className="firstp">
                            The yard check process is outdated. Yard checks are
                            slow, manual, and error-prone, making it difficult
                            to locate vehicles, track status updates, and ensure
                            availability for customers.
                        </p>
                        <h3>Business Goal</h3>
                        <p className="firstp">
                            Decrease time spent on yard checks and increase
                            accuracy of inventory tracking to ultimately improve
                            operational efficiency and customer satisfaction.
                        </p>
                        <h3>Solution</h3>
                        <p className="firstp">
                            A digital yard check system that shows only vehicles
                            currently on the lot, provides real-time status
                            updates, and post-yard-check issue tracking, making
                            it easier to track and manage fleet availability.
                        </p>
                        <h3>Results</h3>
                        <p className="firstp">
                            The software is being piloted in three locations,
                            reducing yard check times by 53% and saving 6-8
                            hours of work per location each week. Employees have
                            praised the software for providing better visibility
                            into their lot.
                        </p>
                        <hr></hr>
                    </div>
                </div>
            </div>
            <SmallPhoto link={ym6} subtitle="Paper Yard Check. Page 1 of 16." />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h2>Background</h2>
                        <h3 className="first">The Yard Check Process:</h3>
                        <p className="firstp">
                            Twice daily, at 6 am and 3 pm, an employee conducts
                            a Yard Check. This involves physically walking
                            through the lot to verify that all expected vehicles
                            are present, to identify any unexpected or missing
                            vehicles, and to locate any issues with the lot
                            itself such as potholes, broken fences, etc. Any
                            vehicles that need to be moved or have other issues
                            or questions are added to a list for follow ups to
                            ensure the appropriate actions are taken.
                        </p>
                        <p>
                            The primary goal of the Yard Check is to maintain an
                            accurate understanding of the vehicles on the lot,
                            ensuring the inventory available for rental is up to
                            date.
                        </p>
                        <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
                            "Yard Checks are to set the staff up for success. We
                            will not have a successful day if the yard check is
                            not good" - Location Manager
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto link={ym7} subtitle="" />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h3 className="first">Problems with the Process</h3>
                        <p className="firstp">
                            User research uncovered several inefficiencies in
                            the Yard Check process.
                        </p>

                        <p>
                            <b>Time-Consuming and Inefficient:</b> Yard checks
                            take over an hour due to large, outdated documents
                            listing up to 600 vehicles, even though only 10%-15%
                            are physically on-site. Associates must sift through
                            unnecessary data to identify the 80 or so vehicles
                            actually present.
                        </p>

                        <p>
                            <b>Difficulty Finding Vehicles:</b> Lots are
                            organized by vehicle status, but it's common to find
                            vehicles not in their designated areas. This forces
                            associates to search the lot manually, wandering
                            until the vehicles are located or confirmed to be
                            absent.
                        </p>
                        <p>
                            <b>Quickly Outdated:</b> A completed yard check is
                            outdated within minutes as new vehicles arrive or
                            rentals leave, making the information less reliable
                            as the day progresses.
                        </p>
                        <p>
                            <b>Missed Vehicles: </b> Occasionally vehicles
                            aren’t marked present despite being on the lot. This
                            leads to complications such as charging customers
                            for an extra day or denying a customer a rental
                            because of lack of inventory, even though a truck is
                            present.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto link={yc8} subtitle="Early Yard Manager Wireframe" />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h3>Business Goal</h3>
                        <p className="firstp">
                            <b>
                                Decrease time spent on yard checks and increase
                                accuracy of inventory tracking.
                            </b>
                            These would allow associates to spend more time on
                            other tasks such as helping customers, preparing
                            vehicles for rental, and transferring vehicles
                            between locations. This also decreases the
                            possibility that a customer is turned away even
                            though the needed vehicle is present.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto
                link={ym2}
                subtitle="My Yard page: Browse inventory and easily see vehicle info and update location within the yard"
            />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h2>Solution</h2>
                        <h3 className="first">
                            Digitize the Yard Check process
                        </h3>
                        <p className="firstp">
                            Moving the yard check to a software solution will
                            allow us to present a location’s vehicle list in a
                            more interactive and user friendly way, interact
                            with other systems to reduce work post-yard check,
                            create a basic task management component to organize
                            post-yard check activities, and identify which
                            vehicles are on the lot by using a vehicle’s
                            telematics to improve inventory tracking.
                        </p>

                        {/* <p style={{ margin: 0 }}>
                            <ul
                                className="subul"
                                style={{
                                    listStyleType: 'none',
                                }}>
                                <li style={{}}>
                                    <b
                                        style={{
                                            fontFamily: 'Plus Jakarta Sans',
                                        }}>
                                        Stopped Alert:
                                    </b>{' '}
                                    Notification when a vehicle is stopped for a
                                    user-defined duration.
                                </li>
                                <li style={{ marginTop: '8px' }}>
                                    <b
                                        style={{
                                            fontFamily: 'Plus Jakarta Sans',
                                        }}>
                                        Movement Alert:
                                    </b>{' '}
                                    Notification when a vehicle moves after
                                    being stationary for 15 minutes.
                                </li>
                                <li style={{ marginTop: '8px' }}>
                                    {' '}
                                    <b
                                        style={{
                                            fontFamily: 'Plus Jakarta Sans',
                                        }}>
                                        Functioning Tracker Alert:
                                    </b>{' '}
                                    Notification when a tracker is able to
                                    reestablish its connection with Vehicle
                                    Finder.
                                </li>
                            </ul>
                        </p> */}
                        {/* <p className="subh first">Stopped Alerts</p>
                        <p className="subp firstp">
                            Notification when a vehicle is stopped for a
                            user-defined duration.
                        </p>
                        <p className="subh">Movement Alerts</p>
                        <p className="subp firstp">
                            Notification when a vehicle moves after being
                            stationary for 15 minutes.
                        </p>
                        <p className="subh">Functioning Tracker Alerts</p>
                        <p className="subp firstp">
                            Notification when a tracker pings for the first time
                            after a week without activity.
                        </p> */}
                    </div>
                </div>
            </div>
            <SmallPhoto
                link={ym3}
                subtitle="Yard Check Mode: One tap to confirm a vehicle is present"
            />

            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h3>Keeping the Focus to Vehicles on the Lot</h3>
                        <p className="firstp">
                            Only vehicles with “on lot” statuses are shown,
                            allowing users to get the task done quicker instead
                            of having to sift through up to hundreds of vehicles
                            that are not present. “Off lot” vehicles are still
                            kept in a drop down, but are not immediately shown
                            to the user to reduce clutter.
                        </p>
                        <p>
                            A search bar is also present allowing users to
                            locate vehicles directly to further reduce search
                            time.
                        </p>
                        <p>
                            Taking advantage of vehicle telematics, we can also
                            show associates exactly where a vehicle is on the
                            lot to help them locate it faster via the map view.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto
                link={ym4}
                subtitle="Map View: Locate vehicles in the yard"
            />

            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h3>Real-Time Yard Management</h3>
                        <p className="firstp">
                            Yard checks set the baseline for each shift and help
                            identify edge cases, but for between checks I
                            created a way to manage vehicle locations in the
                            yard.
                        </p>
                        <p>
                            I performed user research to determine which
                            information should be shown to users as well as
                            worked with the data team to understand which
                            systems this could connect to to pull and edit data.
                        </p>
                        <p>
                            As vehicles arrive to the lot, customers or tow
                            trucks will often drop off a vehicle without
                            notifying an associate. Adding the “new to the yard"
                            column allows us to take advantage of GPS data and
                            let associates know new vehicles have arrived. They
                            can then place them in the appropriate area of the
                            lot, set the appropriate status, send them to
                            maintenance, or prepare to rent them back out as
                            needed.{' '}
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto
                link={ym5}
                subtitle="Task Creation modal: Quickly create tasks for specific vehicles"
            />
            <SmallPhoto link={yc9} subtitle="Task Screen" />

            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <hr></hr>
                        <h3 className="first">Results</h3>
                        <p className="firstp">
                            The system is on a trial run at three locations and
                            has{' '}
                            <b>
                                successfully reduced the yard check process time
                                by about 50% at each location saving about 6-8
                                hours of work per location per week.
                            </b>{' '}
                            Employees have also given positive feedback on the
                            “new to the yard" feature stating that it gives them
                            better visibility into what’s on their lot.
                        </p>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h2></h2>
                        <p></p>
                    </div>
                </div>
            </div>
            <div className="section" id="content-desktop">
                <div className="container">
                    <div className="project-bar-container">
                        <div
                            className="project-container-active"
                            onClick={() => navigate(`/yardmanager/`)}>
                            <img className="project-photo" src={ym}></img>
                            <div className="project-name">
                                Penske: Yard Manager
                            </div>
                        </div>
                        <div
                            className="project-container"
                            onClick={() => navigate(`/penske/`)}>
                            <img
                                className="project-photo"
                                src={penskecover}></img>
                            <div className="project-name">
                                Penske: Subscriptions
                            </div>
                        </div>
                        <div
                            className="project-container"
                            onClick={() => navigate(`/liveunlimited`)}>
                            <img className="project-photo" src={lu}></img>
                            <div className="project-name">Live Unlimited</div>
                        </div>
                        <div
                            className="project-container"
                            onClick={() => navigate(`/otherwork/`)}>
                            <img
                                className="project-photo"
                                src={otherwork}></img>
                            <div className="project-name">Other Work</div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
export default CyberCMS;
